import React from "react";
import image from "./assets/chicago-lake.jpg";
import { makeStyles, Typography } from "@material-ui/core";
import { motion } from "framer-motion";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundImage: `url(${image})`,
    height: "100vh",
    margin: 0,
    backgroundPosition: "center",
    backgroundSize: "cover",
    overflow: "auto",
    backgroundRepeat: "no-repeat",
  },
  box: {
    backgroundColor: "rgba(0,0,0,.7)",
    color: "#fff",
    width: "80%",
    display: "flex",
    justifyContent: "center",
    aspectRatio: 10 / 5,
    margin: "auto",
    marginTop: "3%",
    marginBottom: "3%",
    whiteSpace: "pre-wrap",
    flexDirection: "column",
  },
  textbox: {
    marginLeft: "5%",
    marginRight: "5%",
    marginBottom: "3%",
    marginTop: "3%",
  },
  centerbox: {
    justifyContent: "center",
    display: "flex",
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#e3e7e8",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
  opacity: 0.9,
}));

export default function Projects(props) {
  const classes = useStyles();

  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className={classes.page}>
        <div className={classes.box}>
          <div className={classes.textbox}>
            <h1>Projects</h1>
            <Typography className={classes.text} variant="subtitle1">
              Including this website, which I built from scratch with React.js
              <br />
              <br />
            </Typography>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={6}>
                <StyledPaper
                  sx={{
                    my: 1,
                    mx: "auto",
                    p: 2,
                  }}
                >
                  <div className={classes.centerbox}>
                    <Avatar
                      variant="square"
                      src="wordleai.jpg"
                      sx={{ width: 200, height: 200 }}
                    ></Avatar>
                  </div>
                  <br />
                  <Typography variant="subtitle1">
                    <strong>Wordle AI</strong>
                  </Typography>
                  <p>Made with: Python</p>
                  <Typography>
                    This app has four different components; the primary one is
                    the Wordle AI. The app picks a word from the dictionary and
                    plays Wordle against itself, using the game rules and clues
                    to intelligently find the answer in under 6 guesses. You can
                    also play Wordle against the computer in the terminal. Try
                    it out!
                  </Typography>
                  <br />

                  <Button
                    variant="contained"
                    href="https://github.com/cepiel/WordleAI"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Github
                  </Button>
                </StyledPaper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledPaper
                  sx={{
                    my: 1,
                    mx: "auto",
                    p: 2,
                  }}
                >
                  <div className={classes.centerbox}>
                    <Avatar
                      variant="square"
                      src="barduino_1.jpg"
                      sx={{ width: 200, height: 200 }}
                    ></Avatar>
                  </div>

                  <br />
                  <Typography variant="subtitle1">
                    <strong>Arduino Bartender</strong>
                  </Typography>
                  <p>Made with: Arduinos, C</p>
                  <Typography>
                    The Arduino Bartender is an Arduino and battery powered
                    machine that uses serial communication and peristaltic pumps
                    to mix drinks for you! There are several buttons
                    representing cocktail options, and with one button press,
                    the LCD screen lights up telling you what drink is being
                    mixed. Peristaltic pumps are activated for preset amounts of
                    time to pull drink's ingredients and pour them into your cup
                    in the perfect ratio!
                  </Typography>
                  <br />

                  <Button
                    variant="contained"
                    href="https://github.com/cepiel/arduino_drink_mixer"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Github
                  </Button>
                </StyledPaper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledPaper
                  sx={{
                    my: 1,
                    mx: "auto",
                    p: 2,
                  }}
                >
                  <div className={classes.centerbox}>
                    <Avatar
                      variant="square"
                      src="ar-gallery.jpg"
                      sx={{ width: 200, height: 200 }}
                    ></Avatar>
                  </div>

                  <br />

                  <Typography variant="subtitle1">
                    <strong>Augmented Reality Art Gallery</strong>
                  </Typography>
                  <p>Made with: C#, Unity, Blender, Vuforia</p>
                  <Typography>
                    This augmented art gallery is an improvement on the standard
                    audio tour by bringing the art pieces to life with 3D text,
                    multiple slides of information about the art piece, artist,
                    and more. It also shows pictures of other works by the
                    artist and a video of someone explaining the art piece or
                    artist. On each text slide there is a 3D model with
                    animations that you can activate by clicking the audio
                    button, which gestures and reads the slide.
                  </Typography>
                  <br />

                  <Button
                    variant="contained"
                    href="https://github.com/cepiel/AR-Art-Gallery"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Github
                  </Button>
                </StyledPaper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledPaper
                  sx={{
                    my: 1,
                    mx: "auto",
                    p: 2,
                  }}
                >
                  <div className={classes.centerbox}>
                    <Avatar
                      variant="square"
                      src="vr-office.jpg"
                      sx={{ width: 200, height: 200 }}
                    ></Avatar>
                  </div>
                  <br />
                  <Typography variant="subtitle1">
                    <strong>Virtual Reality Office</strong>
                  </Typography>
                  <p>Made with: C#, Unity, Blender, Vuforia</p>
                  <Typography>
                    I customised a cubicle in an existing office space, adding
                    various items. I added objects with sound effects,
                    interactable objects, and more. Many of the models I found
                    online, but some of them I created with the 3D modeling
                    software called Blender.
                  </Typography>
                  <br />

                  <Button
                    variant="contained"
                    href="https://github.com/cepiel/VR-Office"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Github
                  </Button>
                </StyledPaper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledPaper
                  sx={{
                    my: 1,
                    mx: "auto",
                    p: 2,
                  }}
                >
                  <div className={classes.centerbox}>
                    <Avatar
                      variant="square"
                      src="ar-book.jpg"
                      sx={{ width: 200, height: 200 }}
                    ></Avatar>
                  </div>

                  <br />
                  <Typography variant="subtitle1">
                    <strong>Augmented Reality Book</strong>
                  </Typography>
                  <p>Made with: C#, Unity, Blender, Vuforia</p>
                  <Typography>
                    This augmented reality project uses Unity and Vuforia to
                    create a 3D scene and interactive objects around a bookcover
                    when the book is spotted. The book used is Gods and Heroes
                    of Ancient Greece, and the objects that surround it fit the
                    theme.
                  </Typography>
                  <br />

                  <Button
                    variant="contained"
                    href="https://github.com/cepiel/AR-The-Augmented-Book"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Github
                  </Button>
                </StyledPaper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledPaper
                  sx={{
                    my: 1,
                    mx: "auto",
                    p: 2,
                  }}
                >
                  <div className={classes.centerbox}>
                    <Avatar
                      variant="square"
                      src="crimes_app_1.jpg"
                      sx={{ width: 200, height: 200 }}
                    ></Avatar>
                  </div>
                  <br />

                  <Typography variant="subtitle1">
                    <strong>Chicago Crimes Web-App</strong>
                  </Typography>
                  <p>Made with: C#, HTML, SQL, ASP.NET</p>
                  <Typography>
                    A web application that pulls from the Chicago crime database
                    to display statistics like top 10 crimes, top crime areas,
                    arrest rates, etc. Also allows searching of specific areas
                    or crimes based on user input. Implements SQL queries
                    embedded in C# code to pull information and create tables
                    from the official Chciago crimes data.
                  </Typography>
                  <br />

                  <Button
                    variant="contained"
                    href="https://github.com/cepiel/chicago_crimes_web"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Github
                  </Button>
                </StyledPaper>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
