import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Projects from "./Projects.js";
import Contact from "./Contact.js";
import Home from "./Home.js";
import Navbar from "./Navbar.js";
import "./App.css";
import { AnimatePresence } from "framer-motion";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <Navbar />

      <AnimatePresence exitBeforeEnter initial={true}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
