import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import image from "./assets/chicago-bean.jpg";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundImage: `url(${image})`,
    height: "100vh",
    margin: 0,
    backgroundPosition: "center",
    backgroundSize: "cover",
    overflow: "auto",
    backgroundRepeat: "no-repeat",
  },
  box: {
    backgroundColor: "rgba(0,0,0,.7)",
    color: "#fff",
    width: "80%",
    display: "flex",
    justifyContent: "center",
    aspectRatio: 10 / 5,
    margin: "auto",
    marginTop: "3%",
    marginBottom: "3%",
    whiteSpace: "pre-wrap",
    flexDirection: "column",
  },
  centerbox: {
    justifyContent: "center",
    display: "flex",
    marginTop: "3%",
    marginBottom: "3%",
  },
  text: {
    fontFamily: "Open Sans",
    color: "#fff",
  },
  testlabel: {
    color: "#fff",
  },
  textbox: {
    marginLeft: "5%",
    marginRight: "5%",
    marginBottom: "3%",
    marginTop: "3%",
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginBottom: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#e3e7e8",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const Contact = () => {
  const classes = useStyles();
  const [status, setStatus] = useState("Submit");
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    let response = await fetch("https://www.lucascepiel.com/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    alert(result.status);
  };

  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className={classes.page}>
        <div className={classes.box}>
          <div className={classes.textbox}>
            <h1>Contact</h1>
            <Typography variant="subtitle1" className={classes.text}>
              If you want to chat, reach out to me here, or on LinkedIn. Thanks!
            </Typography>
            <br />

            <div className={classes.centerbox}>
              <form onSubmit={handleSubmit}>
                <div>
                  <InputLabel shrink htmlFor="name">
                    <Typography className={classes.text}>Your name:</Typography>
                  </InputLabel>
                  <BootstrapInput id="name" label="Your Name" required />
                </div>
                <div>
                  <InputLabel shrink htmlFor="email">
                    <Typography className={classes.text}>
                      Your email:
                    </Typography>
                  </InputLabel>
                  <BootstrapInput id="email" label="Your Email" required />
                </div>
                <div>
                  <InputLabel shrink htmlFor="name">
                    <Typography className={classes.text}>
                      Your message:
                    </Typography>
                  </InputLabel>
                  <BootstrapInput id="message" label="Your Message" required />
                </div>
                <br />
                <Button variant="contained" type="submit">
                  {status}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Contact;
