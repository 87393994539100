import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import image from "./assets/chicago-skyline.jpg";
import { motion } from "framer-motion";
import "./index.css";
import Avatar from "@mui/material/Avatar";

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundImage: `url(${image})`,
    height: "100vh",
    margin: 0,
    backgroundPosition: "center",
    backgroundSize: "cover",
    overflow: "auto",
    backgroundRepeat: "no-repeat",
  },
  box: {
    backgroundColor: "rgba(0,0,0,.7)",
    color: "#fff",
    width: "80%",
    display: "flex",
    justifyContent: "center",
    aspectRatio: 10 / 5,
    margin: "auto",
    marginTop: "3%",
    marginBottom: "3%",
    whiteSpace: "pre-wrap",
    flexDirection: "column",
  },
  text: {
    fontFamily: "Open Sans",
  },
  textbox: {
    marginLeft: "5%",
    marginRight: "5%",
    marginBottom: "3%",
    marginTop: "3%",
  },
  centerbox: {
    justifyContent: "center",
    display: "flex",
  },
}));

function Home(props) {
  const classes = useStyles();

  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className={classes.page}>
        <div className={classes.box}>
          <div className={classes.textbox}>
            <Typography variant="h6" className={classes.text}>
              Hey, I'm <strong>Lucas</strong>
            </Typography>
            <Typography className={classes.text} variant="caption">
              <br />
              Find me on <strong>Linkedin</strong> and <strong>Github</strong>{" "}
              with the icons on the top right!
            </Typography>
            <br />
            <br />
            <div className={classes.centerbox}>
              <Avatar
                alt="Lucas Cepiel"
                src="lucas.jpg"
                sx={{ width: 300, height: 300 }}
              />
            </div>
            <Typography className={classes.text}>
              <br /> I'm a <strong>Software Engineer consultant</strong>. I work
              with many different clients, collaborate with them to understand
              their needs, and build the best solution for them. I utilize
              software design best practices and things like Agile and Scrum
              ceremonies. I use tech like AWS, Python, Java, Kotlin, SQL,
              and many more. I have previous experience working in the
              Cybersecurity industry and in the U.S. Army. I earned a Bachelor's
              Degree in Computer Science at the University of Illinois at
              Chicago.
            </Typography>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Home;
