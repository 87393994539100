import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SocialIcon } from "react-social-icons";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Dimensions } from "react-native";
const { width } = Dimensions.get("window");

const useStyles = makeStyles((theme) => ({
  navlinks: {
    display: "flex",
  },
  logobox: {
    flexgrow: 1,
  },
  logo: {
    cursor: "pointer",
    color: "white",
    fontFamily: "Open Sans",
    flex: "1",
    fontSize: fontSizer(width) + 2,
  },
  logo2: {
    cursor: "pointer",
    color: "white",
    fontFamily: "Open Sans",
    fontSize: fontSizer(width) - 4,
  },
  link: {
    textDecoration: "none",
    color: "white",
    flexGrow: "1",
    "&:hover": {
      color: "gold",
    },
  },
  header: {
    // background: "#2193b0",  /* fallback for old browsers */
    // background: "-webkit-linear-gradient(to left, #6dd5ed, #2193b0)",  /* Chrome 10-25, Safari 5.1-6 */
    // background: "linear-gradient(to left, #6dd5ed, #2193b0)", /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    // background: "#c0c0aa" /* fallback for old browsers */,
    // background:
    // "-webkit-linear-gradient(to right, #c0c0aa, #1cefff)" /* Chrome 10-25, Safari 5.1-6 */,
    background:
      "linear-gradient(to right, #c0c0aa, #1cefff)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
    // overflow: "scroll"
  },
  iconrow: {
    flexGrow: "1",
    marginRight: theme.spacing(1),
    alignItems: "flex-end",
    textAlign: "right",
  },
  icons: {
    marginLeft: theme.spacing(1),
  },
  buttongroup: {
    marginLeft: theme.spacing(5),
  },
}));

function fontSizer(screenWidth) {
  if (screenWidth > 800) {
    return 22;
  } else if (screenWidth > 600) {
    return 20;
  } else if (screenWidth > 500) {
    return 18;
  } else if (screenWidth > 400) {
    return 16;
  } else if (screenWidth > 300) {
    return 14;
  } else {
    return 12;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FFD700",
    },
  },
});

function Navbar() {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.header}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Toolbar>
          <div className={classes.logobox}>
            <Typography className={classes.logo}>Lucas Cepiel</Typography>
            <Typography variant="subtitle2" className={classes.logo2}>
              Software Engineer
            </Typography>
          </div>

          <div variant="h3" className={classes.navlinks}>
            <ButtonGroup
              variant="text"
              aria-label="text button group"
              className={classes.buttongroup}
            >
              <Button>
                <Link to="/" className={classes.link}>
                  Bio
                </Link>
              </Button>

              <Button>
                <Link to="/projects" className={classes.link}>
                  Projects
                </Link>
              </Button>

              <Button>
                <Link to="/contact" className={classes.link}>
                  Contact
                </Link>
              </Button>
            </ButtonGroup>
          </div>

          <div className={classes.iconrow}>
            <SocialIcon
              url="https://www.linkedin.com/in/lucas-cepiel/"
              target="_blank"
              className={classes.icons}
              style={{ height: 45, width: 45 }}
            />
            <SocialIcon
              url="https://github.com/cepiel"
              target="_blank"
              className={classes.icons}
              style={{ height: 45, width: 45 }}
            />
          </div>
        </Toolbar>
      </ThemeProvider>
    </AppBar>
  );
}

export default Navbar;
